var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"OSUzDdLKvV0F365AyEe78"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init as SentryInit } from '@sentry/nextjs';
import { captureConsoleIntegration } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

SentryInit({
  dsn:
    SENTRY_DSN ||
    'https://19fef2725299b2fda1c1b2731c35f0f5@o1160911.ingest.sentry.io/4506376631025664',
  enabled:
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
    process.env.NODE_ENV === 'production',
  sampleRate: 0.1,
  integrations: [
    captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  ignoreTransactions: ['/api/healthcheck'],
  tracesSampler: ({ transactionContext }) => {
    const urlPath = transactionContext.name;

    if (urlPath.includes('/orders')) {
      return 1.0; // 100% sampling rate for Manage Orders page
    }

    if (urlPath.includes('/manage-products')) {
      return 1.0; // 100% sampling rate for Manage Products page
    }

    if (urlPath.includes('/rto-returns')) {
      return 0.7; // 70% sampling rate for Manage RTO/Returns page
    }

    if (urlPath.includes('/reports')) {
      return 0.4; // 40% sampling rate for Reports page
    }

    if (urlPath.includes('/payments')) {
      return 0.4; // 40% sampling rate for Payments page
    }

    return 0.1; // Default 10% sampling rate for other pages
  },
});
